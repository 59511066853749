<template>
  <!-- <notice-list-component /> -->
  <notice-list-origin />
</template>

<script>
import NoticeListComponent from "../../components/notice/NoticeListComponent.vue";
import NoticeListOrigin from "../../components/notice/NoticeListOrigin.vue";
export default {
  components: { NoticeListComponent, NoticeListOrigin },
  mounted() {
    this.$emit("set-bar", "공지사항");
  },
};
</script>

<style></style>
