<template>
  <div class="app-context">
    <v-container class="notice-container">
        <v-row justify="center">
          <v-col cols="1">
          </v-col>
          <v-col cols="10" class="pl-0 pr-0">
            <v-row>
              <v-col md="9" cols="6">
                <span class="title notice-title">공지사항</span>
              </v-col>
              <v-col md="3" cols="6">
                <v-text-field 
                  class="notice-text-field"
                  placeholder="검색어 입력" 
                  v-model="searchNotice"
                  append-icon="mdi-magnify"
                  @keyup.enter="load()"
                  @click:append="load()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="list.length > 0">
              <v-col class="item-prop" cols="12" >
                <v-row>
                  <v-col cols="12">
                    <v-row class="item-prop-name" >
                      <v-col md="1" cols="2" class="text-center">
                        번호
                      </v-col>
                      <v-col md="8" cols="6" class="text-center">
                        제목
                      </v-col>
                      <v-col md="3" cols="4" class="text-center">
                        작성일
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(listItem, index) in list"
                  :key="index + 'K'"
                  @click="goDetail(listItem.noticeId)"
                  style="cursor: pointer;"
                >
                  <v-col cols="12" class="item-list">
                    <v-row class="item-list-row">
                      <v-col md="1" cols="2" class="text-center">
                        <p>{{ param.totalCount - (param.pageNo - 1) * 10 - index }}</p>
                      </v-col>
                      <v-col md="8" cols="6" class="text-left">
                        <p 
                          class="list-item-title"
                        >
                          {{ listItem.title }}
                        </p>
                      </v-col>
                      <v-col md="3" cols="4" class="text-center">
                        <p>{{ listItem.creDt }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center pagination-col">
                <div class="pagination-wrap">
                  <p
                    class="navigation-act prev"
                    v-if="listStatus.first > 5"
                    @click="navigatorAct('prev')"
                  >
                    Prev
                  </p>
                  <ul id="pagination">
                    <li
                      v-for="(pagination, index) in listStatus.currentPaginationList"
                      :key="index + 'j'"
                      @click="loadList(pagination, index)"
                    >
                      <p>{{ pagination }}</p>
                    </li>
                  </ul>
                  <p
                    class="navigation-act next"
                    v-if="
                      listStatus.next > 5 &&
                      listStatus.pageCount < listStatus.totalPage && listStatus.last !== listStatus.totalPage
                    "
                    @click="navigatorAct('next')"
                  >
                    Next
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <div class="list-empty">
                  <div class="spinner" v-if="isChildLoading">
                    <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
                      <beat-loader
                        :loading="isChildLoading"
                        :color="loader.color"
                        :size="loader.size"
                      ></beat-loader>
                    </transition>
                  </div>
                  <div class="item">
                    <div class="logo-wrap">
                      <img src="@/assets/images/logo_unplugged.svg" />
                    </div>
                    <h4>조회결과 없음</h4>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
    </v-container>

    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: {
    DefaultPopup,
    BeatLoader,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "introduce",
        sideTitle: "회사소개",
      },
      popupSet: {},
      setCount: false,
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      listHeader: ["번호", "제목", "내용", "날짜"],
      list: [],
      searchNotice: "",
      param: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        this.handleGoback();
      };
    }
    this.load();
  },
  mounted() {

  },
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("notice", ["NOTICE_LIST"]),
    /** 시작일자 종료일자가 0 이면 자동으로 됩니다. 01월01일, 투데이 넣어줌 */
    async goDetail(param) {
      await this.$router.push(`/v/notice/noticeDetail/${param}`).catch(()=>{});
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(()=>{});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },

    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      if (this.listStatus.totalDataCnt >= 1) {
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
        searchValue: this.searchNotice,
        
      };
      try {
        await this.NOTICE_LIST(searchOpt).then((data) => {
          // console.log("NOTICE_LIST DATA", data);
          this.listStatus.totalDataCnt = data.data.param.totalCount;
          for (let i = 0; i < data.data.list.length; i++) {
            list.unshift(data.data.list[i]);
          }

          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = list;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
        this.listStatus.totalDataCnt = 122;
         */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalDataCnt", totalDataCnt);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          this.loadList(this.listStatus.last, 4);
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            // console.log(
            //   "재료",
            //   document.querySelector("#pagination").childNodes[k].childNodes[0]
            //     .textContent
            // );
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.listStatus.totalDataCnt >= 1) {
          this.$nextTick((_) => {
            document
              .querySelector("#pagination")
              .childNodes[this.listStatus.currentPage - 1].classList.add(
                "active"
              );
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    async load() {
      this.initListStatus();
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
        searchValue: this.searchNotice,
        
      };
      try {
        await this.NOTICE_LIST(searchOpt).then((data) => {
          //console.log("NOTICE_LIST DATA", data);
          this.param= data.data.param;
          this.listStatus.totalDataCnt = data.data.param.totalCount;
          for (let i = 0; i < data.data.list.length; i++) {
            list.unshift(data.data.list[i]);
          }

          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = list;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "../../assets/scss/NoticeList.scss";
</style>
